import jQuery from './jquery-3.2.1.min';

const $ = jQuery;

const slide = ($kinenoteComment, commentWidth, sec) => {
  // アニメーション
  $kinenoteComment.animate({
    'margin-left': `${-commentWidth}px`,
  }, sec, 'linear', () => {
    $kinenoteComment.find('ul').eq(0).remove();
    $kinenoteComment.append($kinenoteComment.find('ul').clone());

    $kinenoteComment.css('margin-left', 0);
    slide($kinenoteComment, commentWidth, sec);
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const $kinenoteCommentList = $('.kinenote-comment');
  const parentWidth = $('.kinenote-comment-wrapper').width();

  $kinenoteCommentList.each((i, element) => {
    const $kinenoteComment = $(element);
    const $liList = $kinenoteComment.find('ul li');

    // キネノートの長さ
    let commentWidth = 40;
    $liList.each((j, li) => {
      if ($(li).width() > 0) {
        commentWidth += $(li).width() + 40;
      }
    });

    // アニメーションの時間
    const sec = commentWidth * 12;

    $kinenoteComment.css({
      display: 'none',
      visibility: 'visible',
    });

    const isAdd = parentWidth < commentWidth;

    if (isAdd) {
      $kinenoteComment.css('width', `${(commentWidth * 2) + 80}px`);
      $kinenoteComment.find('ul').css('width', `${commentWidth}px`);
      $kinenoteComment.append($kinenoteComment.find('ul').clone());
      slide($kinenoteComment, commentWidth, sec);
    }
  });
});
